<template>
  <div class="header fixed w-full bg-white shadow-sm top-0 left-0 z-60" :class="[isMobile ? 'py-0 ' : 'py-5']">
    <div class="container flex justify-between items-center" :class="{ 'h-14 px-5': isMobile }">
      <template v-if="!isMobile">
        <router-link to="/" class="">
          <g-2-academy color="yellow" />
        </router-link>
        <div class="flex gap-8 items-center">
          <div class="font-semibold text-lg py-1 cursor-pointer" @click="scrollTo('#hero')">HOME</div>
          <div class="font-semibold text-lg py-1 cursor-pointer" @click="scrollTo('#about-section')">ABOUT</div>
          <div class="font-semibold text-lg py-1 border-r-2 pr-8 cursor-pointer" @click="scrollTo('#learnmore')">LEARN MORE</div>
          <div class="flex gap-2">
            <template v-if="!isUserEventLoggedIn">
              <Button buttonText="Login" type="tertiary" @action="showLogin()" />
              <Button buttonText="Register" type="primary" @action="showRegistration()" />
            </template>
            <template v-else>
              <div class="flex items-center cursor-pointer login-item relative">
                <User color="yellow" height="26" width="26" />
                <span class="inline-block ml-2 text-base font-semibold mr-2" :title="userEvent.name">{{ userEvent.name | truncate(10) }}</span>
                <chevron direction="down" color="#F7931E" width="12" height="12" />
                <div class="header__nav__menu-item-submenu invisible opacity-0 ease-linear transition-all duration-300 pt-6 left-2/4 top-full transform -translate-x-2/4 absolute cursor-default z-10">
                  <div class="login-menu bg-white shadow-xl border border-grey-darker rounded relative">
                    <div class="relative z-10 rounded bg-white p-6 text-black">
                      <div class="cursor-pointer text-yellow text-sm" @click="doLogout">Logout</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="pl-8">
            <img src="@/assets/images/logos/aws.svg" alt="aws" />
          </div>
        </div>
      </template>
      <template v-else>
        <router-link to="/" class="">
          <g-2-academy color="yellow" />
        </router-link>
        <div class="flex items-center gap-4">
          <img src="@/assets/images/logos/aws.svg" alt="aws" class="w-20" />
          <span @click="showSideBar()" class="inline-block"><hamburger color="yellow" /></span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { truncate } from '@/utils'
export default {
  filters: {
    truncate
  },
  components: {
    G2Academy: () => import(/* webpackChunkName: "icon" */ '@/components/icons/G2Academy'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Hamburger: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Hamburger'),
    User: () => import(/* webpackChunkName: "icon" */ '@/components/icons/User'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron')
  },
  props: {
    eventStarted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('auth', ['userEvent', 'isUserEventLoggedIn'])
  },
  methods: {
    ...mapActions('auth', ['removeUserEvent']),
    scrollTo(target) {
      this.$emit('scroll', target)
    },
    showRegistration() {
      this.$emit('register')
    },
    showLogin() {
      this.$emit('login')
    },
    showSideBar() {
      this.$emit('sidebar')
    },
    doLogout() {
      const cleanUserData = new Promise((resolve, reject) => {
        resolve(this.removeUserEvent())
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  &__nav {
    &__menu {
      &-item {
        &:hover .header__nav__menu-item-submenu {
          visibility: visible;
          opacity: 1;
          padding-top: 15px;
        }
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 0%;
          content: '.';
          color: transparent;
          background: #aaa;
          height: 2px;
          transition: all 300ms;
        }
        &:hover::after {
          width: 100%;
        }
      }
    }
  }
}
.login-item:hover .header__nav__menu-item-submenu {
  visibility: visible;
  opacity: 1;
  padding-top: 15px;
}
.login-item .header__nav__menu-item-submenu {
  transform: translateX(-50%);
}
.login-menu {
  width: 250px;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: 0;
    margin-left: 6px;
    box-sizing: border-box;
    border: 6px solid black;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(-225deg);
    box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 50%);
    z-index: 1;
  }
}
</style>
